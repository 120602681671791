import { createSlice } from '@reduxjs/toolkit';

const costs = createSlice({
	name: 'costs',
	initialState: [],
	reducers: {
		setCosts: (state: any, { payload }: any) => {
			return payload;
		},
		resetCosts: () => {
			return [];
		},
	},
});

export const { setCosts, resetCosts } = costs.actions;
export default costs;
