export const appConfig = {
	FIREBASE_ENDPOINT: process.env.REACT_APP_FIREBASE_ENDPOINT,
	GRAPHQL_URL: process.env.REACT_APP_GRAPHQL_URL,
	AUTH_TOKEN: process.env.REACT_APP_AUTH_TOKEN,
	STAGE: process.env.REACT_APP_STAGE,
	API_URL: process.env.REACT_APP_API_URL,
};
export const configFirebase = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	appId: process.env.REACT_APP_APP_ID,
};
