import { Suspense, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Container, LinearProgress } from '@mui/material';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Header from '../components/Header';

function Private({ component: Component, ...rest }: any) {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	return (
		<>
			<Header />
			<Box component="main">
				<Container maxWidth={false}>
					<Suspense fallback={<LinearProgress />}>
						<Component />
					</Suspense>
				</Container>
			</Box>
		</>
	);
}

export default Private;
