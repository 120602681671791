import { createSlice } from '@reduxjs/toolkit';

const profiles = createSlice({
	name: 'profiles',
	initialState: [],
	reducers: {
		setProfiles: (state: any, { payload }: any) => {
			return payload;
		},
		resetProfiles: (state: any) => {
			return [];
		},
	},
});

export const { setProfiles, resetProfiles } = profiles.actions;
export default profiles;
