import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Provider } from 'react-redux';
import configureAppStore from './redux/store';
import { ProvideAuth } from './hooks/useAuth';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

export const theme = createTheme({
	palette: {
		primary: {
			main: '#434460',
			light: '#cacdf5',
			dark: '#323348',
			contrastText: '#ffffff',
		},
		secondary: {
			main: '#8689bf',
			light: '#d3d5f7',
		},
		text: {
			primary: '#24272A',
			secondary: 'rgba(36,39,42,0.36)',
		},
	},
	typography: {
		fontFamily: 'Nunito',
		fontSize: 16,
	},
});

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<Provider store={configureAppStore()}>
				<Router>
					<ProvideAuth>
						<App />
					</ProvideAuth>
				</Router>
			</Provider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
