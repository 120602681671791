import { combineReducers } from 'redux';
import user from '../reducers/user.reducer';
import users from '../reducers/users.reducer';
import profiles from '../reducers/profiles.reducer';
import training from '../reducers/training.reducer';
import modules from '../reducers/modules.reducer';
import cobenefits from '../reducers/cobenefits.reducer';
import costs from '../reducers/costs.reducer';

export const rootReducer = combineReducers({
	user: user.reducer,
	users: users.reducer,
	profiles: profiles.reducer,
	training: training.reducer,
	modules: modules.reducer,
	cobenefits: cobenefits.reducer,
	costs: costs.reducer,
});
