import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLogged: false,
	email: '',
	photoURL: '',
	token: '',
};

const user = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state: any, { payload }: any) => {
			return {
				...state,
				...payload,
				isLogged: true,
			};
		},
		resetUser: () => {
			return initialState;
		},
	},
});

export const { setUser, resetUser } = user.actions;
export default user;
