import { createSlice } from '@reduxjs/toolkit';

const cobenefits = createSlice({
	name: 'cobenefits',
	initialState: [],
	reducers: {
		setCobenefits: (state: any, { payload }: any) => {
			return payload;
		},
		resetCobenefits: () => {
			return [];
		},
	},
});

export const { setCobenefits, resetCobenefits } = cobenefits.actions;
export default cobenefits;
