import { Route, Switch } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import ApolloConfig from './config/apolloConfig';
import Login from './Pages/Login';
import Private from './Pages/Private';
import ReactivaRoutes from './Pages/ReactivaRoutes';
import './App.css';

const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: ApolloConfig,
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		},
		query: {
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		},
	},
});

function App() {
	return (
		<ApolloProvider client={client}>
			<Switch>
				<Route path="/login" render={() => <Login />} />
				<Private component={ReactivaRoutes} />
			</Switch>
		</ApolloProvider>
	);
}

export default App;
