import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
	Avatar,
	AppBar,
	Box,
	Toolbar,
	IconButton,
	Typography,
	Menu,
	MenuItem,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../redux/selectors/user.selector';
import { useAuth } from '../hooks/useAuth';

function Header() {
	const auth: any = useAuth();
	const { photoURL } = useSelector(getUserSelector);
	const [anchorEl, setAnchorEl] = useState(null);
	const handleMenu = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleLogout = () => {
		handleClose();
		auth?.signout();
	};

	return (
		<AppBar position="static">
			<Toolbar>
				<Box sx={{ display: 'flex', flexGrow: 1, color: 'white' }}>
					<NavLink
						to="/"
						className="menu"
						activeClassName="menu-active"
						color="#eaeaea"
						isActive={(match, location) =>
							!/usuarios/.test(location.pathname) &&
							!/accesibilidad/.test(location.pathname)
						}
					>
						<Typography variant="h6" color="white" mx={1}>
							Dashboard
						</Typography>
					</NavLink>
					<NavLink
						to="/usuarios"
						activeClassName="menu-active"
						className="menu"
						isActive={(match, location) => /usuarios/.test(location.pathname)}
					>
						<Typography variant="h6" color="white" mx={1}>
							Usuarios
						</Typography>
					</NavLink>
					<NavLink
						to="/accesibilidad"
						activeClassName="menu-active"
						className="menu"
						isActive={(match, location) => /accesibilidad/.test(location.pathname)}
					>
						<Typography variant="h6" color="white" mx={1}>
							Accesibilidad
						</Typography>
					</NavLink>
				</Box>

				<div>
					<IconButton
						size="large"
						aria-label="account of current user"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleMenu}
						color="inherit"
					>
						<Avatar alt="Remy Sharp" src={photoURL} />
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
					</Menu>
				</div>
			</Toolbar>
		</AppBar>
	);
}

export default Header;
