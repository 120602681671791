import { useEffect, useState } from 'react';
import { Box, Button, Container, Snackbar, Grid, TextField, Alert } from '@mui/material';
import { useHistory } from 'react-router-dom';
import {
	getAuth,
	signInWithRedirect,
	signInWithCustomToken,
	signInWithPopup,
	getRedirectResult,
	GoogleAuthProvider,
} from 'firebase/auth';
import { GoogleLogin } from 'react-google-login';
import { ReactComponent as ReactivaLogo } from '../assets/logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSelector } from '../redux/selectors/user.selector';
import { providerGoogle } from '../config/firebaseConfig';
import { setUser } from '../redux/reducers/user.reducer';
import { appConfig } from '../config/appConfig';

const auth = getAuth();

function Login() {
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [authorized, setAuthorized] = useState(false);
	const user = useSelector(getUserSelector);
	const history = useHistory();

	useEffect(() => {
		if (user.isLogged) {
			history.push('/');
		}
	}, []);

	const onSignin = async () => {
		const response = await fetch(`${appConfig.API_URL}dashlogin`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email }), // body data type must match "Content-Type" header
		});
		const { payload } = await response.json();

		if (!payload.authorized) {
			setAuthorized(!payload.authorized);
			return;
		}
		signInWithCustomToken(auth, payload.authorized);
	};

	const handleClose = (event: any, reason: any) => {
		if (reason === 'clickaway') {
			return;
		}
		setAuthorized(false);
	};

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
			>
				<Container component="main" maxWidth="xs">
					<Grid
						container
						spacing={4}
						direction="column"
						justifyContent="space-around"
						alignItems="center"
					>
						<Grid item>
							<Box sx={{ m: 2, width: 64, height: 64 }}>
								<ReactivaLogo width="100%" height="100%" />
							</Box>
						</Grid>
						<Grid item>
							<TextField
								id="email"
								label="Email"
								variant="outlined"
								type="email"
								onChange={({ target }) => setEmail(target.value)}
								value={email}
							/>
						</Grid>
						<Grid item>
							<Button variant="contained" type="button" onClick={onSignin}>
								Iniciar
							</Button>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Snackbar
				open={authorized}
				autoHideDuration={4000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="error" variant="filled" sx={{ width: '100%' }}>
					Usuario no autorizado
				</Alert>
			</Snackbar>
		</>
	);
}

export default Login;
