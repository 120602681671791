import React, { useEffect, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import axios from 'axios';
import store from 'storejs';
import { signOut } from 'firebase/auth';
import { auth } from '../config/firebaseConfig';
import { getUserSelector } from '../redux/selectors/user.selector';
import { resetUser, setUser } from '../redux/reducers/user.reducer';
import { resetUsers } from '../redux/reducers/users.reducer';
import { resetProfiles } from '../redux/reducers/profiles.reducer';

const AuthContext = React.createContext({});

function useProvideAuth() {
	const dispatch = useDispatch();
	const history = useHistory();
	const userProfile = useSelector(getUserSelector, shallowEqual);

	const signout = () => {
		dispatch(resetUser());
		dispatch(resetUsers());
		dispatch(resetProfiles());
		return signOut(auth);
	};

	useEffect(() => {
		const unsubscribeAuthSChange = auth.onAuthStateChanged(async (user) => {
			if (user) {
				store.set('session', await user.getIdToken());
				axios.defaults.headers.common['Authorization'] =
					'Bearer ' + (await user.getIdToken());
				dispatch(
					setUser({
						email: user.email,
						photoURL: user.photoURL,
					})
				);
				history.push('/');
			}
			if (!user) {
				store.remove('session');
				history.push('/login');
			}
		});
		return () => {
			unsubscribeAuthSChange();
		};
	}, []);
	return {
		userProfile,
		signout,
	};
}
export function ProvideAuth({ children }: any) {
	const auth = useProvideAuth();
	const store = useMemo(() => auth, [auth]);
	return <AuthContext.Provider value={store}>{children}</AuthContext.Provider>;
}
export const useAuth = () => {
	return useContext(AuthContext);
};
