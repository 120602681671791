import { initializeApp } from 'firebase/app';
import {
	connectAuthEmulator,
	getAuth,
	setPersistence,
	browserSessionPersistence,
	GoogleAuthProvider,
} from 'firebase/auth';
import { configFirebase } from '../config/appConfig';

initializeApp(configFirebase);
export const auth = getAuth();
// connectAuthEmulator(auth, 'http://localhost:9099');
setPersistence(auth, browserSessionPersistence);

export const providerGoogle = new GoogleAuthProvider();
