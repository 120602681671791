import { Redirect, Route, Switch } from 'react-router-dom';
import { lazy } from 'react';

const Dashboard = lazy(() =>
	import('./Dashboard').then((module) => ({
		default: module.default,
	}))
);
const Users = lazy(() =>
	import('./Users').then((module) => ({
		default: module.default,
	}))
);
const Accessibility = lazy(() =>
	import('./Accessibility').then((module) => ({
		default: module.default,
	}))
);

function ReactivaRoutes() {
	return (
		<Switch>
			<Route exact path="/" component={Dashboard} />
			<Route exact path="/usuarios" component={Users} />
			<Route exact path="/accesibilidad" component={Accessibility} />
			<Route render={() => <Redirect to="/" />} />
		</Switch>
	);
}

export default ReactivaRoutes;
