import { createSlice } from '@reduxjs/toolkit';

const training = createSlice({
	name: 'training',
	initialState: [],
	reducers: {
		setTraining: (state: any, { payload }: any) => {
			return payload;
		},
		resetTraining: (state: any) => {
			return [];
		},
	},
});

export const { setTraining, resetTraining } = training.actions;
export default training;
