import { createSlice } from '@reduxjs/toolkit';

const modules = createSlice({
	name: 'modules',
	initialState: [],
	reducers: {
		setModules: (state: any, { payload }: any) => {
			return payload;
		},
		resetModules: () => {
			return [];
		},
	},
});

export const { setModules, resetModules } = modules.actions;
export default modules;
