import { createSlice } from '@reduxjs/toolkit';

const users = createSlice({
	name: 'users',
	initialState: [],
	reducers: {
		setUsers: (state: any, { payload }: any) => {
			return payload;
		},
		resetUsers: (state: any) => {
			return [];
		},
	},
});

export const { setUsers, resetUsers } = users.actions;
export default users;
