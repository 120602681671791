import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers/index';

export default function configureAppStore() {
	const middlewares = [thunk];

	if (process.env.NODE_ENV === 'development') {
		const { logger } = require(`redux-logger`);
		middlewares.push(logger);
	}

	const store = configureStore({
		reducer: rootReducer,
		devTools: process.env.NODE_ENV !== 'production',
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
			}).concat(middlewares),
	});

	if (process.env.NODE_ENV !== 'production' && module?.hot) {
		module?.hot.accept('./reducers/', () => {
			const nextReducer = require('./reducers/').default;
			store.replaceReducer(nextReducer);
		});
	}

	return store;
}
